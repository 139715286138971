:root {
  --aside-width: 250px;
}
aside {
  width: var(--aside-width);
  overflow-y: auto;
  padding-top: 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  .navElem {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    color: black;
    text-align: center;
    text-decoration: none;
    padding: 0 25px 0 40px;
    transition: 300ms;
    border: none;
    background: none;
    border-right: 2px solid white;

    &:hover {
      border-color: var(--primary-color);
      cursor: pointer;
    }
    &.highlighted {
      color: var(--primary-color);
    }
    .arrow {
      font-size: 12px;
    }
    &.tree {
      padding-left: 60px;
    }
  }
  .urlBox {
    max-height: 0;
    transition: max-height 0.3s ease-out;
    overflow: hidden;
    &.opened {
      max-height: 100%;
      transition: max-height 0.3s ease-in;
    }
  }
}
.content {
  position: relative;
  width: calc(100% - var(--aside-width));
  height: 100%;
}
