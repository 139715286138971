:root {
  --padding: 30px;
}

.blast {
  position: relative;
  width: calc(100% - var(--padding) * 2);
  height: calc(100% - var(--padding) * 2);
  padding: var(--padding);
}
