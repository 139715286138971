.home {
  height: 100%;
  width: 100%;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.layout {
  height: 100%;
  width: 100%;
  max-width: 1200px;
  .widgets {
    height: 50%;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    .widget {
      height: 100%;
      background-color: var(--primary-background);
      width: calc(100% / 3);
      border-radius: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:not(:first-child) {
        margin-left: 40px;
      }
    }
  }
  .graph {
    height: 50%;
    width: 100%;
    padding-top: 40px;
    box-sizing: border-box;
  }
}
