:root {
  --description-height: 40px;
}
.gold {
  position: relative;
  width: 100%;
  height: 100%;
  .description {
    width: 100%;
    height: var(--description-height);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
  }
  .calculation {
    margin-top: 20px;
    > div {
      display: flex;
      align-items: center;
      height: 40px;
      .input {
        margin-left: 15px;
        width: 150px;
      }
    }
  }
}
